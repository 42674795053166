import { createDippUrl } from 'lib/urls'

export function buildFullUrl(path?: string) {
  if (!path) {
    return createDippUrl('/')
  }
  return createDippUrl(path)
}

export function addGATrackingParams(
  url = '',
  source?: string,
  medium?: string,
  campaign?: string
) {
  const separator = url.search(/\?/) === -1 ? '?' : '&'
  return `${url}${separator}utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`
}
