import { useCurrentUser } from 'hooks/useCurrentUser'

import { useEffect } from 'react'
import * as gtag from '../../lib/gtag'

const DEPRECATED_MAILINGLIST_STORAGE_KEY = 'thedipp:softpaywall'

/**
 * NOTES ON GA TERMS
 * userTypes:
 * - 'Anonymous': means the person visiting has no previous information with us
 * - 'Lead': legacy term meaning someone signed up for a mailing list and got an id from there
 * - 'User': a user who has an account but is not a subscribing member
 * - 'Subscriber': a user who is subscribing
 */

export function GoogleAnalytics({
  customDimensions,
}: {
  customDimensions?: GACustomDimensions
}) {
  const { user, loading } = useCurrentUser()

  useEffect(() => {
    if (loading) {
      return
    }

    let publicId
    let userType = 'Anonymous'

    // check for presence of legacy publicId in local storage if there is no user
    if (!user) {
      try {
        const storage = JSON.parse(
          localStorage.getItem(DEPRECATED_MAILINGLIST_STORAGE_KEY) || '{}'
        )
        if (storage?.hasSubmittedEmail) {
          userType = 'Lead'
          publicId = storage?.publicId
        }
      } catch (err) {
        // ignore: Unable to parse localstorage ${STORAGE_KEY}
      }
    }

    if (user) {
      publicId = user.publicId
      userType = user.isSubscribed ? 'Subscriber' : 'User'
    }

    // Stash this globally in the dom for subsequent events on the current page
    gtag.setUserType(userType)

    // Sets the user values for all
    // subsequent GA calls on this page (events, etc)
    gtag.setUserId(publicId)
    // Stash the page details and dimensions from a content page
    // for use on future pages (e.g. /subscribe) as "last page" to record
    // where the event intent originated
    if (customDimensions) {
      gtag.setLastPageDetails()
      gtag.setLastCustomDimensions(customDimensions)
    }
    gtag.pageView({ customDimensions })
    // TODO: CustomDimensions needs to be wrapped in a useCallback everywhere that it gets passed into
    // this component, seems better to disable it for now and refactor that later for correctness.
    // right now if the customDimensions change GA will not be updated because this useEffect
    // doesnt run if that object changes. Long term it would be good to fix
    //eslint-disable-next-line
  }, [user, loading])
  return null
}
