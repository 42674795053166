const isBrowser = typeof window !== 'undefined'

export function trackSignup({
  email,
  uid,
}: {
  email?: string | null
  uid?: string
}) {
  if (isBrowser && typeof window.$FPROM === 'object') {
    const params: { email?: string; uid?: string } = {}
    if (email) {
      params.email = email
    }
    if (uid) {
      params.uid = uid
    }
    window.$FPROM.trackSignup(params)
  }
}

export function getData() {
  if (isBrowser && typeof window.$FPROM === 'object') {
    const data = window.$FPROM.data
    data.hasData = !!(data.ref_id || data.tid || data.url_ref_id)
    return data
  }
}
