import { toTitleCase } from 'lib/string'
import Head from 'next/head'
import { buildFullUrl } from '../helpers'
import {
  UnstructuredImageData,
  StructuredImageData,
  UnstructuredAuthorData,
  StructuredAuthorData,
  StructuredPublisherData,
  UnstructuredMainEntityOfPageData,
  StructuredMainEntityOfPageData,
  UnstructuredPostData,
  StructuredPostData,
  StructuredPaywallPostData,
  UnstructuredListItemData,
  StructuredListItemData,
  UnstructuredBreadcrumbData,
  StructuredBreadcrumbData,
  UnstructuredPageData,
  StructuredPageData,
  StructuredCommunityPostData,
  UnstructuredCommPostData,
} from './types'

const CONTEXT = 'http://schema.org'

function buildStructuredImage({
  url,
  width,
  height,
}: UnstructuredImageData): StructuredImageData {
  return {
    '@type': 'ImageObject',
    url,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  }
}

function buildStructuredAuthor({
  name,
  path,
}: UnstructuredAuthorData): StructuredAuthorData {
  return {
    '@type': 'Person',
    name,
    url: buildFullUrl(path),
  }
}

function buildStructuredPublisher(): StructuredPublisherData {
  return {
    '@type': 'Organization',
    name: 'The Dipp',
    url: buildFullUrl(),
    logo: buildStructuredImage({
      url:
        'https://thedipp.imgix.net/2020/08/TheDipp_Logo_circle-D-black-copy-1.png?q=100',
      width: 128,
      height: 128,
    }),
  }
}

function buildStructuredMainEntityOfPage({
  url,
}: UnstructuredMainEntityOfPageData): StructuredMainEntityOfPageData {
  return {
    '@type': 'WebPage',
    '@id': url,
  }
}

function buildStructuredPost({
  url,
  title,
  description,
  datePublished,
  dateUpdated,
  articleSection,
  tags,
  authors,
  image,
  isFree,
}: UnstructuredPostData): StructuredPostData | StructuredPaywallPostData {
  const datePublishedStr = datePublished.toISOString()
  const dateUpdatedStr = dateUpdated
    ? dateUpdated.toISOString()
    : datePublishedStr
  const paywallFields = {
    isAccessibleForFree: 'False',
    hasPart: {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: '.overlay',
    },
  }
  return {
    '@type': 'Article',
    headline: toTitleCase(title),
    description,
    articleSection: toTitleCase(articleSection),
    keywords: tags.map((t) => toTitleCase(t.name)),
    datePublished: datePublishedStr,
    dateModified: dateUpdatedStr,
    mainEntityOfPage: buildStructuredMainEntityOfPage({ url }),
    author: authors.map((a) => buildStructuredAuthor(a)),
    image: buildStructuredImage(image),
    publisher: buildStructuredPublisher(),
    ...(isFree ? {} : paywallFields),
  }
}

function buildStructuredListItem({
  position,
  name,
  item,
}: UnstructuredListItemData): StructuredListItemData {
  return {
    '@type': 'ListItem',
    position,
    name,
    item,
  }
}

function buildStructuredBreadcrumb({
  breadcrumbs,
}: UnstructuredBreadcrumbData): StructuredBreadcrumbData {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((bc) => buildStructuredListItem(bc)),
  }
}

function buildStructuredPage({
  url,
  title,
  description,
  image,
}: UnstructuredPageData): StructuredPageData {
  return {
    '@type': 'WebPage',
    headline: toTitleCase(title),
    description,
    mainEntityOfPage: buildStructuredMainEntityOfPage({ url }),
    image: buildStructuredImage(image),
    publisher: buildStructuredPublisher(),
  }
}

// function buildStructuredComment({
//   upvoteCount,
//   downvoteCount,
//   url,
//   description,
//   discussionUrl,
//   author,
//   topicName,
// }: UnstructuredCommentData): StructuredCommentData {
//   const titlizedTag = toTitleCase(topicName)
//   return {
//     '@type': 'Comment',
//     upvoteCount,
//     downvoteCount,
//     url,
//     description,
//     discussionUrl,
//     keywords: [titlizedTag],
//     author: buildStructuredAuthor(author),
//   }
// }

function buildStructuredCommPost({
  url,
  title,
  description,
  datePublished,
  dateUpdated,
  topicName,
  author,
  image,
  commentCount,
}: UnstructuredCommPostData): StructuredCommunityPostData {
  const datePublishedStr = datePublished.toISOString()
  const dateUpdatedStr = dateUpdated
    ? dateUpdated.toISOString()
    : datePublishedStr
  const titlizedTag = toTitleCase(topicName)
  return {
    '@type': 'BlogPosting',
    headline: toTitleCase(title),
    description,
    articleSection: titlizedTag,
    keywords: [titlizedTag],
    datePublished: datePublishedStr,
    dateCreated: datePublishedStr,
    dateModified: dateUpdatedStr,
    commentCount,
    // comment: comments.map((c) => buildStructuredComment({ ...c, topicName })),
    mainEntityOfPage: buildStructuredMainEntityOfPage({ url }),
    author: buildStructuredAuthor(author),
    image: buildStructuredImage(image),
    publisher: buildStructuredPublisher(),
  }
}

interface StructuredDataProps {
  post?: UnstructuredPostData
  breadcrumbs?: UnstructuredBreadcrumbData['breadcrumbs']
  page?: UnstructuredPageData
  commPost?: UnstructuredCommPostData
}
export function StructuredData({
  post,
  breadcrumbs,
  page,
  commPost,
}: StructuredDataProps) {
  const structuredData = []
  if (post) {
    structuredData.push({ '@context': CONTEXT, ...buildStructuredPost(post) })
  }
  if (page) {
    structuredData.push({ '@context': CONTEXT, ...buildStructuredPage(page) })
  }
  if (commPost) {
    structuredData.push({
      '@context': CONTEXT,
      ...buildStructuredCommPost(commPost),
    })
  }
  if (breadcrumbs) {
    structuredData.push({
      '@context': CONTEXT,
      ...buildStructuredBreadcrumb({ breadcrumbs }),
    })
  }
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </Head>
  )
}
