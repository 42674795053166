export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export function toTitleCase(str: string) {
  return str.replace(/\w+/g, capitalize)
}

export function stripDashes(str: string) {
  return str.replace(/-/g, ' ')
}

export function shortenToLength(str: string, length: number) {
  const contentsArray = str.split(' ')
  if (contentsArray.length > length) {
    return `${contentsArray.slice(0, length).join(' ')}...`
  }

  return contentsArray.join(' ')
}
