export type ImageSize = [number] | [number, number]
export type ImgixFm =
  | 'gif'
  | 'jp2'
  | 'jpg'
  | 'json'
  | 'jxr'
  | 'pjpg'
  | 'mp4'
  | 'png'
  | 'png8'
  | 'png32'
  | 'webm'
  | 'webp'

const defaultImgixParams: [string, string][] = [
  ['auto', 'format,compress'],
  ['cs', 'srgb'],
  ['q', '70'],
  ['crop', 'faces'],
  ['fit', 'crop'],
]

interface BuildImgixURLOptions {
  size?: ImageSize
  fm?: ImgixFm
  dpr?: number
}

export function buildImgixURL(
  url: string,
  { size, fm = 'jpg', dpr = 1 }: BuildImgixURLOptions = {}
) {
  if (!url.includes('cms.thedipp.com') && !url.includes('thedipp.imgix.net')) {
    return url
  }

  const imgixUrl = url.replace(
    'https://s3-us-east-2.amazonaws.com/cms.thedipp.com',
    'https://thedipp.imgix.net'
  )
  const imgSrc = new URL(imgixUrl)

  if (/\.gif$/.test(imgSrc.pathname)) {
    return imgSrc.toString()
  }

  defaultImgixParams.forEach((params) => imgSrc.searchParams.append(...params))

  if (size) {
    const [width, height] = size
    imgSrc.searchParams.append('w', String(width))

    if (height) {
      imgSrc.searchParams.append('h', String(height))
    }
  }

  if (/\.gif$/.test(imgSrc.pathname)) {
    if (process.browser) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      )
      if (!isSafari) {
        imgSrc.searchParams.append('fm', 'webp')
      }
    } else {
      imgSrc.searchParams.append('fm', 'webp')
    }
  } else {
    imgSrc.searchParams.append('fm', fm)
  }

  imgSrc.searchParams.append('dpr', String(dpr))

  return imgSrc.toString()
}
