// https://github.com/garmeeh/next-seo/blob/f1c1f091c4d57471fbfc3c4ccd972d80b1f65891/src/meta/buildTags.tsx
import Head from 'next/head'
import { ReactNode } from 'react'

interface SeoTagsProps {
  url: string
  title: string
  twitterTitle?: string
  ogTitle?: string
  description: string
  openGraph: {
    image: {
      url: string
      width: string | number
      height: string | number
    }
  }
  children?: ReactNode
}

export function SeoTags(props: SeoTagsProps) {
  const {
    url,
    title,
    twitterTitle,
    ogTitle,
    description,
    openGraph: {
      image: { url: imageUrl, width, height },
    },
    children,
  } = props

  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {/* open graph */}
      <meta property="og:site_name" content="The Dipp" />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={String(width)} />
      <meta property="og:image:height" content={String(height)} />
      {/* constants */}
      <meta name="twitter:site" content="@thedipp" />
      <meta property="twitter:title" content={twitterTitle || title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="fb:app_id" content="328555454849160" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/thedippdotcom"
      />
      {children}
    </Head>
  )
}
