import * as firstPromoter from './firstPromoter'
const isBrowser = typeof window !== 'undefined'

export function setLastCustomDimensions(customDimensions: GACustomDimensions) {
  if (!isBrowser) {
    return
  }
  customDimensions.eventPagePath = location.pathname
  localStorage.setItem('GACustomDimensions', JSON.stringify(customDimensions))
}

export function getLastCustomDimensions() {
  try {
    const customDimensions = localStorage.getItem('GACustomDimensions')
    return customDimensions ? JSON.parse(customDimensions) : null
  } catch (err) {
    return null
  }
}

export function setLastPageDetails(pageDetailsParam?: GAPageDetails) {
  if (!isBrowser) {
    return
  }
  const defaultPageDetails = {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
  }
  const pageDetails = Object.assign(defaultPageDetails, pageDetailsParam || {})

  localStorage.setItem('GAPageDetails', JSON.stringify(pageDetails))
}

export function getLastPageDetails() {
  try {
    const pageDetails = localStorage.getItem('GAPageDetails')
    return pageDetails ? JSON.parse(pageDetails) : null
  } catch (err) {
    return null
  }
}

export function pageView({
  customDimensions,
}: {
  customDimensions?: GACustomDimensions
} = {}) {
  event('page_view', { customDimensions })
}

export function signUpSoftPaywall({
  customDimensions,
}: {
  customDimensions?: GACustomDimensions
} = {}) {
  event('generate_lead', {
    customDimensions,
  })
}

export function signUpLinkPaywall({
  customDimensions,
}: {
  customDimensions?: GACustomDimensions
} = {}) {
  event('paywall_signup_link', {
    customDimensions,
  })
}

export function signInLinkPaywall({
  customDimensions,
}: {
  customDimensions?: GACustomDimensions
} = {}) {
  event('paywall_signin_link', {
    customDimensions,
  })
}

export function signUp() {
  event('sign_up', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function createTopLevelCommentLink() {
  event('create_top_level_comment_link', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function commentReplyLink() {
  event('comment_reply_link', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function commentUpvoteLink() {
  event('comment_upvote_link', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function commentDownvoteLink() {
  event('comment_downvote_link', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function commentVote({
  voteType,
}: {
  voteType: 'upvote' | 'downvote' | 'unvote'
}) {
  event('comment_vote', {
    value: voteType,
    customDimensions: getLastCustomDimensions(),
  })
}

export function createComment() {
  event('create_comment', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function replyToComment() {
  event('reply_to_comment', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function updateComment() {
  event('update_comment', {
    customDimensions: getLastCustomDimensions(),
  })
}

export function createSubscription({
  purchaseDetails,
}: {
  purchaseDetails?: GAPurchaseDetails
} = {}) {
  event('purchase', {
    purchaseDetails,
    customDimensions: getLastCustomDimensions(),
  })
}

export function setUserType(userType: string) {
  if (!isBrowser) {
    return
  }
  window.__ga__userType = userType
}

export function getUserType() {
  if (!isBrowser) {
    return
  }
  return window?.__ga__userType || 'Anonymous'
}

export function event(
  event_action: string,
  {
    event_label,
    event_category,
    value,
    customDimensions,
    purchaseDetails,
  }: GAEventParams
) {
  if (!isBrowser) {
    return
  }
  const {
    series,
    network,
    genres,
    byline,
    pageType,
    eventPagePath,
    accessRole,
  } = customDimensions || {}
  let payload = {
    event_category,
    event_label,
    value,
    Series: series,
    Network: network,
    Genres: genres,
    Byline: byline,
    PageType: pageType || 'Other',
    EventPagePath: eventPagePath || location.pathname,
    AccessRole: accessRole,
    UserType: getUserType(),
  }

  try {
    const firstPromoterData = firstPromoter.getData()
    if (firstPromoterData.hasData) {
      payload = Object.assign(payload, {
        PromoterRefId: firstPromoterData.ref_id || null,
        PromoterTrackingId: firstPromoterData.tid || null,
        PromoterUrlRefId: firstPromoterData.url_ref_id || null,
      })
    }
  } catch (e) {
    // Failed to load promoter data for gtag
  }

  if (purchaseDetails) {
    payload = Object.assign(payload, purchaseDetails)
  }
  window.gtag('event', event_action, payload)
}

export function setUserId(userId: string) {
  setConfig({ user_id: userId, userId }) // some docs say one, some the other
}

export function setConfig(config: any) {
  if (!isBrowser) {
    return
  }
  window.gtag('set', config)
}
